nav {
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: block;
    padding: 0.7rem 0.7rem;
    z-index: 2;
    position: fixed;
    left: 1rem;
    bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

nav a {
    position: relative;
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
}

nav a:hover {
    background: rgba(0, 0, 0, 0.3);
    color: var(--color-primary-variant);
}

nav a::after {
    content: attr(data-tooltip);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    background: transparent;
    color: #fff;
    padding: 0.5rem;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: var(--transition);
    font-size: 0.7rem;
}

nav a:hover::after {
    opacity: 1;
    visibility: visible;
}


/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================ */
@media screen and (max-width: 1025px) {

}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================ */
@media screen and (max-width: 700px) {
    nav {
        background: rgba(0, 0, 0, 0.3);
        width: max-content;
        display: block;
        padding: 0.7rem 0.7rem;
        z-index: 2;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        top: 0.05rem;
        bottom: auto;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        border-radius: 3rem;
        backdrop-filter: blur(15px);
    }

    section::before {
        content: "";
        display: block;
        height: 4rem;
        margin-top: -4rem;
    }


    @media screen and (max-width: 380px) {
        nav {
            padding: 0.5rem 0.5rem;
            gap: 0.5rem;
        }

        nav a {
            padding: 0.7rem;
            font-size: 1.1rem;
        }
    }
}