.about_container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about_me {
    width: 100%;
    margin-top: 3rem;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
    display: grid;
    place-items: center;
}

.about_me-image {
    aspect-ratio: 1/1;
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}

.about_me-image:hover {
    transform: rotate(0);
}


.about_content p {
    margin: 2rem 0 2.6rem;
    color: var(--color-light);
}

/* Modal Styling blog_post_modal.css*/
.overlay {
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}

.modalContainer {
    max-width: 800px;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    background-color: #a1a1a1;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
    padding: 1rem;
    border: 2px solid var(--color-primary-variant);
    border-radius: 1rem;
    max-height: 80vh;
    overflow-y: auto;
    font-size: 1rem;
}

.modalContainer h1 {
    color: var(--color-secondary);
    margin-bottom: 3rem;
    font-size: 1.5rem;
}

.modalContainer p {
    color: var(--color-bg);
    text-align: left;
}

.modalRight {
    width: 100%;
}

.closeBtn {
    background-color: #a1a1a1;
    position: fixed;
    width: 30px;
    height: 30px;
    padding: 0;
    top: 10px;
    right: 10px;
    text-align: center;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    line-height: 30px;
    border: 1px solid var(--color-bg);
    border-radius: 0.25rem;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 3rem;
    padding: 1rem 2rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================ */
@media screen and (max-width: 1024px) {
    .about_container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about_me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .about_content p {
        margin: 1rem 0 1.5rem;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================ */
@media screen and (max-width: 600px) {

    .about_me {
        width: 60%;
        margin: 0 auto 3rem;
    }

    .about_content {
        text-align: center;
    }

    .about_content p {
        margin: 1.5rem 0;
        font-size: 0.7rem;
    }

    .modalContainer {
        flex-direction: column;
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
        height: 100vh;
        font-size: .7rem;
    }

    .modalContainer h1 {
        font-size: 1rem;
    }
}

