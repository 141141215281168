.specialties_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}

.specialty {
    background: var(--color-bg-variant);
    border-radius: 0 0 2rem 2rem;
    border: 1px solid var(--color-primary);
    height: fit-content;
    transition: var(--transition);
}

.specialty:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.specialty_head {
    background: var(--color-primary);
    padding: 2rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.specialty_head h3 {
    color: var(--color-bg);
    font-size: 1rem;
    text-align: center;
}

.specialty_list {
    padding: 2rem;
}

.specialty_list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}

.specialty_list-icon {
    color: var(--color-primary);
    font-size: 2rem;
    /*margin-top: 1px;*/
}

.specialty_list p {
    font-size: 0.9rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================ */
@media screen and (max-width: 1024px) {
    .specialties_container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem
    }

    .specialty {
        height: auto;
    }

}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================ */
@media screen and (max-width: 600px) {
    .specialties_container {
        grid-template-columns: 1fr;
        gap: 0.5rem
    }
}