header_navbar {
    display: flex;
    padding: 1rem 2rem;
    text-align: center;
    font-size: 1.25rem;
    justify-content: space-between;
    align-items: center;
}

header_navbar a {
    color: var(--color-primary);
}

header_navbar a:hover {
    color: var(--color-white);
}

.header_navbar-left {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0;
    align-items: center;
}

.header_navbar-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0;
    align-items: center;
}





/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================ */
@media screen and (max-width: 1024px) {
    header_navbar {
        display: none;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================ */
@media screen and (max-width: 600px){
    header_navbar {
        display: none;
    }
}
